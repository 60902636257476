import { Controller } from "@hotwired/stimulus";

/**
 * @memberof shared
 * @module DestroyOnMatchController
 * @controller
 * @property {value} contains - JSON array of string values used to match the input value
 * @property {value} name - Name of form element to check value for destroying
 *
 * @description Set the value of a _destroy input based on the value of the form element that
 * has changed.
 *
 * @example
 * <form>
 *   <input name="radioMe" id="radioMeNo" type="radio" value="no" />
 *   <input name="radioMe" id="radioMeYes" type="radio" value="yes" />
 *   <input
 *     type="hidden"
 *     name="radioMe[_destroy]"
 *     value="false"
 *     data-controller="form--destroy-on-match"
 *     data-form--destroy-on-match-contains-value="[&quot;no&quot;]"
 *     data-form--destroy-on-match-name-value="radioMe"
 *     data-action="change@document->form--destroy-on-match#toggle"
 *   />
 * </form>
 */

export default class DestroyOnMatchController extends Controller {
  static values = {
    contains: Array,
    name: String,
  };

  /**
   * @param target - The target of the event triggering the change
   *
   * @description Set the value of a _destroy input when target matches
   * any of the contained values
   */
  toggle({ target }) {
    if (target.name !== this.nameValue) { return; }
    this.element.value = this.#targetContainsValue(target);
  }

  #targetContainsValue(target) {
    if (target.type === "checkbox") {
      return target.checked;
    }
    if (target.type === "radio" && !target.checked) { return false; }

    return this.containsValue.includes(target.value);
  }
}
